<template>
  <div class="feed">
    <v-card :loading="loading" class="mb-2">
      <v-toolbar elevation="2" flat>
        <v-card-subtitle>
          <strong>O que é o Dialeticos?</strong>
        </v-card-subtitle>
      </v-toolbar>

      <v-card-text class="black--text">
        <v-row class="ma-2">
          <p style="text-align: justify">
            {{ dialeticsExplanation }}
          </p>
        </v-row>

        <v-divider class="mx-8"></v-divider>

        <v-row justify="center" class="mx-2 mt-3 mb-2">
          <h3>Poderes</h3>
        </v-row>

        <v-row v-if="stateEntities.length">
          <v-col class="d-flex flex-row flex-wrap">
            <StateEntityCard
              v-bind:key="entity.id"
              v-for="entity in stateEntities"
              :entity="entity"
              @open-entity-screen="redirectToGamesOfTheEntityScreen"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card :loading="loading">
      <v-toolbar elevation="2" flat>
        <v-card-subtitle>
          <strong>Meus debates</strong>
        </v-card-subtitle>
      </v-toolbar>

      <v-card-text>
        <!-- <div class="without-games mx-3" v-if="!games.length && isStudent">
          <v-alert border="top" colored-border type="info" elevation="2">
            <h3>
              Ops! Parece que você ainda não se conectou a nenhuma sala ou suas
              salas não possuem debates!
            </h3>

            <p class="mt-3">
              Se no menu lateral não houver nenhuma sala que você participe,
              busque por sua instituição abaixo e solicite admissão a uma sala
              para ter acesso.
            </p>

            <p class="mt-3">
              Se houver, aguarde a criação de um debate e eles aparecerão aqui.
            </p>
          </v-alert>
        </div>

        <div class="without-games mx-3" v-if="!games.length && !isStudent">
          <v-alert border="top" colored-border type="info" elevation="2">
            <h3>
              Ops! Parece que você ainda não se conectou a nenhuma instituição
              ou suas salas não possuem debates!
            </h3>

            <p class="mt-3">
              Se no menu lateral não houver nenhuma instituição que você participe,
              busque por sua instituição abaixo e solicite admissão para ter
              acesso.
            </p>

            <p class="mt-3">
              Se houver, crie ou aguarde a criação de um debate e eles
              aparecerão aqui.
            </p>
          </v-alert>
        </div> -->

        <v-card
          v-bind:key="game.id"
          v-for="game in games"
          class="my-3 mx-3"
          elevation="2"
        >
          <v-toolbar color="grey lighten-4" elevation="0" flat>
            <v-card-subtitle>
              {{ game.StateEntity.name }}: {{ game.theme }}
            </v-card-subtitle>
          </v-toolbar>

          <v-card-text>
            <v-row no-gutters>
              <v-col class="ml-5">
                <span v-if="game.Room" class="classroom-name">{{
                  game.Room.description
                }}</span>
              </v-col>
            </v-row>

            <v-row class="mt-2" no-gutters>
              <v-col class="ml-2">
                <v-btn
                  small
                  text
                  @click="redirectToGameScreen(game.RoomId, game.id)"
                  ><v-img width="10" class="mr-1" :src="phiIconSrc"></v-img>
                  Debate</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-dialog v-model="successDialog" max-width="400">
        <v-card>
          <v-card-text>
            <div class="text-center pa-4">
              <v-icon large color="success">mdi-check</v-icon>
            </div>

            <div class="text-center pa-3">
              <h3>Operação realizada com sucesso</h3>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click.stop="successDialog = false">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    <v-card v-if="this.userData.Group.group_code != 4" class="mt-2">
      <v-toolbar elevation="1" flat>
        <v-card-subtitle> <strong>Buscar</strong> </v-card-subtitle>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col class="d-flex flex-row">
            <v-text-field
              v-model="filters.search"
              clearable
              placeholder="Busca por nome da instituição"
            ></v-text-field>
            <v-btn
              class="mx-2 my-3"
              color="primary"
              @click="loadInstitutions()"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-expansion-panels>
              <v-expansion-panel
                v-for="(item, i) in institutionOptions"
                :key="i"
              >
                <v-expansion-panel-header color="grey lighten-4">
                  {{ item.institution_name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div
                    class="teacher-content mt-4"
                    v-if="userData.Group.group_code == 2"
                  >
                    <v-alert
                      class="mt-3 not-request-alert"
                      v-if="
                        checkifParticipantAndIfAuthorized(item.InstitutionUsers)
                      "
                      outlined
                      color="primary"
                      border="left"
                    >
                      Você ja é participante ou sua solicitação está pendente
                    </v-alert>

                    <v-btn
                      class="mt-3"
                      v-else
                      small
                      text
                      @click="applyForAdmission(item.id)"
                    >
                      <v-icon left>mdi-arrow-right-bold-box</v-icon> Solicitar
                      admissão</v-btn
                    >
                  </div>

                  <div
                    class="students-content mt-5 mb-2"
                    v-if="userData.Group.group_code == 1"
                  >
                    <v-row
                      v-for="(room, i) in item.Rooms"
                      :key="i"
                      class="room-option"
                    >
                      <v-col cols="3" class="d-flex align-center"
                        >Sala: {{ room.description }}</v-col
                      >
                      <v-col class="d-flex align-center">
                        <v-alert
                          class="not-request-alert"
                          v-if="
                            checkifParticipantAndIfAuthorized(
                              room.RoomParticipants
                            )
                          "
                          outlined
                          color="primary"
                          border="left"
                        >
                          Você ja é participante ou sua solicitação está
                          pendente
                        </v-alert>

                        <v-btn
                          v-else
                          small
                          text
                          @click="applyForAdmission(item.id, room.id)"
                        >
                          <v-icon left>mdi-arrow-right-bold-box</v-icon>
                          Solicitar admissão</v-btn
                        >
                      </v-col>
                    </v-row>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import StateEntityCard from "@/components/StateEntities/StateEntityCard.vue";
import phiIcon from "@/assets/fi-black.png";
import { mapState } from "vuex";

export default {
  components: {
    StateEntityCard,
  },
  computed: {
    ...mapState("user", ["userData"]),
    roomId() {
      return this.$route.params.id;
    },
    isStudent() {
      return this.userData.Group.group_code == 1;
    },
  },
  data() {
    return {
      loading: false,
      successDialog: false,
      phiIconSrc: phiIcon,
      headers: [
        { text: "Nome", value: "fullName" },
        { text: "Email", value: "email" },
        { text: "Ações", value: "actions" },
      ],
      games: [],
      institutionOptions: [],
      stateEntities: [],
      dialeticsExplanation: null,
      filters: {
        search: null,
      },
    };
  },
  methods: {
    async loadFeedData() {
      try {
        this.loading = true;

        const response = await this.$axios.get(`/feed`);

        this.setData(response.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setData(data) {
      const cpData = { ...data };

      const auxGames = [...cpData.games];
      let gamesToInsert = [];

      for (const auxGame of auxGames) {
        if (gamesToInsert.findIndex((g) => g.id == auxGame.id) == -1)
          gamesToInsert.push(auxGame);
      }

      this.games = [...gamesToInsert];

      // console.log(this.games);
    },
    async getDialeticsInstitutionData() {
      try {
        this.loading = true;

        const res = await this.$axios.get(`/instituicoes/dialeticos`);

        const cpData = { ...res.data };

        this.dialeticsExplanation = cpData.explanation;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async loadInstitutions() {
      // console.log(this.userData);

      try {
        this.loading = true;

        let url =
          this.userData.Group.group_code === 2
            ? "/instituicoes/professor/buscar"
            : "/instituicoes/aluno/buscar";

        const response = await this.$axios.get(url, {
          params: {
            search: this.filters.search,
          },
        });

        this.institutionOptions = [...response.data];

        console.log(response.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setInstitutionsForStudents(data) {
      const cpData = [...data];

      console.log(cpData);
    },

    checkifParticipantAndIfAuthorized(array) {
      if (!array.length) return false;

      for (const user of array) {
        if (user.id == this.userData.id) return true;
      }

      return false;
    },
    async applyForAdmission(institutionId, roomId = null) {
      try {
        const userType =
          this.userData.Group.group_code === 2 ? "teacher" : "student";

        const msg =
          userType === "teacher"
            ? `Solicitar admissão para esta instituição?`
            : `Solicitar admissão para esta sala?`;

        const confirm = await this.$root.$confirm("Atenção", msg);

        if (!confirm) return;

        this.loading = true;

        let url =
          userType === "teacher"
            ? "/instituicoes/professor/solicitar_admissao"
            : "/instituicoes/aluno/solicitar_admissao";

        const dataToSend = {
          userId: this.userData.id,
          institutionId,
        };

        if (roomId) dataToSend.roomId = roomId;

        await this.$axios.post(url, dataToSend);

        this.resetInstitutionOptions();

        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async loadStateEntities() {
      try {
        this.loading = true;

        const response = await this.$axios.get(`/poderes`);

        this.setEntities(response.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setEntities(data) {
      const cpData = [...data];

      this.stateEntities = cpData.map((e) => ({
        ...e,
      }));
    },
    redirectToGameScreen(roomId, gameId) {
      this.$router.push(`/sala/${roomId}/jogo/${gameId}`);
    },
    redirectToGamesOfTheEntityScreen(entityId) {
      this.$router.push(`/dialeticos/poder/${entityId}/jogos`);
    },
    resetInstitutionOptions() {
      this.institutionOptions = [];
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
  },
  created() {
    this.loadFeedData();
    this.getDialeticsInstitutionData();
    this.loadStateEntities();
  },
};
</script>

<style scoped>
.classroom-name {
  color: black;
  font-size: 1.1rem;
}

.room-option {
  border: 1px solid grey;
  border-radius: 5px;
}

.not-request-alert {
  margin-bottom: 0;
}
</style>
